import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class LockersService {
  constructor(private http: HttpClient) {}

  getLockers(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/locker/list`);
  }

  getLocker(id: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/locker/${id}`);
  }

  getGavetas(id: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/locker/${id})
        `);
  }
  
  createLocker(locker: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/locker/create`, locker);
  }


 


  updateLocker(id: string, locker: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/lockers/update/${id}`, locker);
  }

  deleteLocker(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/lockers/${id}`);
  }

  getLockerUser(id: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/locker/user/${id}`);
  }

  createLockerUser(id: string, user: { user_id: string; username: string; password: string; id_locker: string }): Observable<any> {
    return this.http.post(`${environment.apiUrl}/locker/signup`, user);
  }

  generatePassword(username: string, password: string): Observable<any> {
    const body = { username, password };
    return this.http.patch(`${environment.apiUrl}/locker/generateNewPassword`, body);
  }

  updateLockerStatus(id: string, status: boolean): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/locker/status/${id}`, { status });
  }

  updateLockerUserInfo(id: string, user: { user_id: string; username: string;  password: string }): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/locker/edit/${id}`, user);
  }
}
