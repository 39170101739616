<div class="ml-36 pt-20 w-auto h-[100vh]">
    <div class="flex top-1/2 justify-center items-center p-6 ml-36 h-full" *ngIf="!cotizar && !isloading" >
        <div class="bg-[#F0F0F0] rounded-[49px] w-full shadow-lg">
            <!-- Destino -->
            <form #quoteForm="ngForm" (ngSubmit)="cotizarEnvio(quoteForm)" class="p-6">                            
              <div class="grid grid-cols-3 gap-6">
                <!-- Columna 1: Destino -->
                <div class="flex flex-col p-2 w-full">
                  <span class="text-dagblue text-[30px] font-extrabold uppercase mb-4">Destino</span>
                  <div class="space-y-3">
                    <div class="radio-group border rounded-full w-full">
                      <input type="radio" id="nacional" name="tipo" class="w-full" value="Nacional" [(ngModel)]="selectedOption" (change)="onOptionChange()">
                      <label for="nacional" class="w-full text-center">Nacional</label>
                      
                      <input type="radio" id="internacional" name="tipo" class="w-full" value="Internacional" [(ngModel)]="selectedOption" (change)="onOptionChange()">
                      <label for="internacional" class="w-full text-center">Internacional</label>
                    </div>  
                    <input type="text" name="cp_origen" [(ngModel)]="quote.cp_origen" class="block rounded-full p-3 w-full text-sm text-gray-900 border-2 appearance-none focus:border-dagblue focus:outline-none" placeholder="C.P. Origen" required />
                    <input type="text" name="cp_destino" [(ngModel)]="quote.cp_destino" class="block rounded-full p-3 w-full text-sm text-gray-900 border-2 appearance-none focus:border-dagblue focus:outline-none" placeholder="C.P. Destino" required />
                    <select *ngIf="showCity" name="pais_destino" [(ngModel)]="quote.pais_destino" id="pais_destino" class="block rounded-full p-3 w-full text-sm text-gray-900 border-2 appearance-none focus:border-dagblue focus:outline-none" required>
                      <option value="" disabled selected>Selecciona un país</option>
                      <option *ngFor="let country of countries" [value]="country.code">
                        {{country.name}}
                      </option>
                    </select>
                  </div>
            
                  <!-- Seguro -->
                  <span class="text-dagblue text-[30px] font-extrabold uppercase mt-6 mb-4">Seguro</span>
                  <div class="space-y-3">
                    <div class="radio-group rounded-full w-full border-2">
                      <input type="radio" id="noInsured" name="seguro" value="No asegurado" [(ngModel)]="isInsured" (change)="hasInsurance()">
                      <label for="noInsured" class="w-full text-center">No asegurado</label>
                      
                      <input type="radio" id="Insured" name="seguro" value="Asegurado" [(ngModel)]="isInsured" (change)="hasInsurance()">
                      <label for="Insured" class="w-full text-center">Asegurado</label>
                    </div>
                    <div *ngIf="showValue" class="relative w-full">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-400">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                      </div>
                      <input type="number" [(ngModel)]="quote.valor_declarado" name="valor_declarado" class="block rounded-full p-3 pl-10 w-full text-sm text-gray-900 border-2 appearance-none focus:border-dagblue focus:outline-none" placeholder="Valor declarado" required />
                    </div>
                  </div>
                </div>
            
                <!-- Columna 2: Tipo de envío -->
              <div class="flex flex-col p-2 w-full">
                <span class="text-dagblue text-[30px] font-extrabold uppercase mb-4">Tipo de envío</span>
                <div class="space-y-3">
                  <div class="radio-group border-2 rounded-full w-full">
                    <input type="radio" id="shipment" name="shippingType" value="Sobre" [(ngModel)]="shippingType" (change)="shipmentType()">
                    <label for="shipment" class="w-full text-center">Sobre</label>

                    <input type="radio" id="paquete" name="shippingType" value="Paquete" [(ngModel)]="shippingType" (change)="shipmentType()">
                    <label for="paquete" class="w-full text-center">Paquete</label>
                  </div>
                  <div *ngIf="!showType" class="bg-yellow-100 border-2 flex items-center gap-2 text-yellow-600 border-dagyel rounded-lg p-3">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-dagyel">
                      <path fill-rule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-sm">El sobre debe tener una medida máxima de un documento tamaño oficio y un peso máximo de 1kg.</span>
                  </div>    
                  <div *ngIf="showType" class="space-y-3">
                    <input type="number" [(ngModel)]="quote.peso" [value]="quote.peso === 0 ? '' : quote.peso" class="block rounded-full p-3 w-full text-sm text-gray-900 border-2 border-gray-300 bg-gray-100 focus:border-dagblue focus:outline-none" placeholder="Peso (kg)" />
                  
                    <input type="number" [(ngModel)]="quote.largo" [value]="quote.largo === 0 ? '' : quote.largo" class="block rounded-full p-3 w-full text-sm text-gray-900 border-2 border-gray-300 bg-gray-100 focus:border-dagblue focus:outline-none" placeholder="Largo (cm)" />
                  
                    <input type="number" [(ngModel)]="quote.ancho" [value]="quote.ancho === 0 ? '' : quote.ancho" class="block rounded-full p-3 w-full text-sm text-gray-900 border-2 border-gray-300 bg-gray-100 focus:border-dagblue focus:outline-none" placeholder="Ancho (cm)" />
                  
                    <input type="number" [(ngModel)]="quote.alto" [value]="quote.alto === 0 ? '' : quote.alto" class="block rounded-full p-3 w-full text-sm text-gray-900 border-2 border-gray-300 bg-gray-100 focus:border-dagblue focus:outline-none" placeholder="Alto (cm)" />
                  </div>
                  
                </div>
              </div>
                <!-- Columna 3: Descripción del paquete (extendida) -->
                <div class="flex flex-col p-2 w-full row-span-2">
                  <span class="text-dagblue text-[30px] font-extrabold uppercase mb-4">Descripción del paquete</span>
                  <div class="flex flex-col h-full">
                    <textarea name="description" id="description" [(ngModel)]="description" class="flex-grow block rounded-[40px] p-3 w-full text-sm text-gray-900 border-2 appearance-none focus:border-dagblue focus:outline-none resize-none" placeholder="Descripción del contenido" required></textarea>
                    <button 
                    type="submit" 
                    class="mt-4 font-bold py-3 px-4 rounded-full transition duration-300 ease-in-out text-white"
                    [ngClass]="{
                      'bg-dagpk hover:bg-opacity-90': quoteForm.valid, 
                      'bg-gray-400 cursor-not-allowed': !quoteForm.valid
                    }" 
                    [disabled]="!quoteForm.valid"
                  >
                    COTIZAR ENVÍO
                  </button>
                  
                  </div>
                </div>
              </div>                
            </form>
        </div>
    </div>    
    <div class="flex justify-start text-center"></div>
    <div *ngIf="cotizar && !isloading" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 gap-2">
      <button (click)="resetCotizacion()" class="mb-8 bg-dagblue text-white px-6 py-3 rounded-lg hover:bg-dagblue-dark transition duration-300 ease-in-out text-lg font-semibold flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
        </svg>
        Volver a Cotizar
      </button>
    
      <div *ngIf="superenviosResult.length > 0">        
        <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6 mb-8">
          <div *ngFor="let paqueteria of superenviosResult" class="bg-gray-100 rounded-[32px] p-4 shadow-lg items-center flex flex-col hover:shadow-lg transition duration-300">
            <img [src]="getProveedorImagePath(paqueteria.proveedor)" [alt]="paqueteria.proveedor" class="h-8 mb-4">
            <div class="p-2 rounded-[28px] bg-white w-[206px] flex flex-col items-center mb-4">
              <p class="text-2xl font-bold text-dagblue mb-2">{{ paqueteria.precio | currency }}</p>
              <p class="text-xs font-bold text-red-500 mb-2">{{paqueteria.precio_regular}}</p>
              <p class="text-sm text-gray-600">{{ paqueteria.tiempo_de_entrega }}</p>
              <p class="text-sm text-gray-600 mb-4">{{ paqueteria.nombre_servicio }}</p>
            </div>
            <button (click)="seleccionarPaqueteria(paqueteria)" class="w-full bg-dagblue text-white py-2 rounded-full hover:bg-dagblue-dark transition duration-300 ease-in-out uppercase text-sm font-semibold">
              Seleccionar
            </button>
          </div>
        </div>
      </div>
    
      <div *ngIf="fedexResult.length > 0">        
        <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6 mb-8">
          <div *ngFor="let paqueteria of fedexResult" class="bg-gray-100 rounded-[32px] p-4 shadow-lg items-center flex flex-col hover:shadow-lg transition duration-300">
            <img [src]="getProveedorImagePath(paqueteria.proveedor)" [alt]="paqueteria.proveedor" class="h-8 mb-4">
            <div class="p-2 rounded-[28px] bg-white w-[206px] flex flex-col items-center mb-4">
              <p class="text-2xl font-bold text-dagblue mb-2">{{ (paqueteria.precio) | currency }}</p> 
              <p class="text-xs font-bold text-red-500 mb-2">{{paqueteria.precio_regular}}</p>             
              <p class="text-sm text-gray-600 mb-4">{{ paqueteria.nombre_servicio }}</p>
              <p class="text-sm text-gray-600 mb-4">{{ paqueteria.idServicio }}</p>
            </div>
            <button (click)="seleccionarPaqueteria(paqueteria)" class="w-full bg-dagblue text-white py-2 rounded-full hover:bg-dagblue-dark transition duration-300 ease-in-out uppercase text-sm font-semibold">
              Seleccionar
            </button>
          </div>
        </div>
      </div>

        <!-- Nueva sección para Paquete Express -->
        <div *ngIf="paqueteexpressResult.length > 0">          
          <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6 mb-8">
            <div *ngFor="let paqueteria of paqueteexpressResult" class="bg-gray-100 rounded-[32px] p-4 shadow-lg items-center flex flex-col hover:shadow-lg transition duration-300">
              <img [src]="getProveedorImagePath(paqueteria.proveedor)" [alt]="paqueteria.proveedor" class="h-8 mb-4">
              <div class="p-2 rounded-[28px] bg-white w-[206px] flex flex-col items-center mb-4">
                <p class="text-2xl font-bold text-dagblue mb-2">{{ paqueteria.precio | currency }}</p>
                <p class="text-xs font-bold text-red-500 mb-2">{{paqueteria.precio_regular}}</p>

                <p class="text-sm text-gray-600">{{ paqueteria.tiempo_de_entrega }}</p>
                <p class="text-sm text-gray-600 mb-4">{{ paqueteria.nombre_servicio }}</p>
              </div>
              <button (click)="seleccionarPaqueteria(paqueteria)" class="w-full bg-dagblue text-white py-2 rounded-full hover:bg-dagblue-dark transition duration-300 ease-in-out uppercase text-sm font-semibold">
                Seleccionar
              </button>
            </div>
          </div>
        </div>
        
        <div *ngIf="dhlResult.length > 0">          
          <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6 mb-8">
            <div *ngFor="let paqueteria of dhlResult"
             class="bg-gray-100 rounded-[32px] p-4 shadow-lg items-center flex flex-col hover:shadow-lg transition duration-300">
              <img [src]="getProveedorImagePath(paqueteria.proveedor)" [alt]="paqueteria.proveedor" class="h-8 mb-4">
              <div class="p-2 rounded-[28px] bg-white w-[206px] flex flex-col items-center mb-4">
                <p class="text-2xl font-bold text-dagblue mb-2">{{ paqueteria.precio | currency }}</p>
                <p class="text-xs font-bold text-red-500 mb-2">{{paqueteria.precio_regular}}</p>
                <p class="text-sm text-gray-600">{{ paqueteria.tiempo_de_entrega }}</p>
                <p class="text-sm text-gray-600 mb-4">{{ paqueteria.nombre_servicio }}</p>
              </div>
              <button (click)="seleccionarPaqueteria(paqueteria)" class="w-full bg-dagblue text-white py-2 rounded-full hover:bg-dagblue-dark transition duration-300 ease-in-out uppercase text-sm font-semibold">
                Seleccionar
              </button>
            </div>
          </div>
        </div>
    
      <!-- <div *ngIf="superenviosResult.length === 0 && fedexResult.length === 0 && dhlResult.length === 0 && paqueteexpressResult.length === 0" class="text-center py-12">
        <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <p class="mt-4 text-xl text-gray-600">No se encontraron cotizaciones disponibles.</p>
        <p class="mt-2 text-gray-500">Por favor, intente con diferentes parámetros de búsqueda.</p>
      </div> -->
    </div>      
      <div class="dagfriend-container">
        <button 
          (click)="toggleBotInstructions()" 
          class="dagfriend-button"
        >
          DAGFRIEND
        </button>
      
        <div *ngIf="showBotInstructions" class="mt-4">
          <img src="assets/bot-instructions.png" alt="Bot Instructions" class="max-w-xs">
        </div>
      </div>
</div>

<div *ngIf="showEmpaqueDialog" class="fixed z-50 inset-0 overflow-y-auto">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 transition-opacity" (click)="closeEmpaqueDialog()">
      <div class="absolute inset-0 backdrop-blur-md bg-white/30 dark:bg-gray-800/30 border border-white/20 dark:border-gray-700/20 shadow-lg">        
      </div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4">
          Seleccionar Empaque
        </h3>
        <div class="grid grid-cols-2 gap-4">
          <div *ngIf="packages.length === 0">No hay empaques disponibles.</div>
          <div *ngFor="let empaque of packages" 
              class="border p-4 rounded-lg cursor-pointer hover:bg-gray-100"
              [class.bg-blue-100]="selectedEmpaque?.name === empaque.name"
              (click)="selectEmpaque(empaque)">
            <img [src]="empaque.image" [alt]="empaque.name" class="w-full h-32 object-cover mb-2">
            <p class="font-bold">{{ empaque.name }}</p>
            <p>{{ empaque.sell_price | currency }}</p>
            <p>Dimensiones: {{ empaque.length }}x{{ empaque.width }}x{{ empaque.heigth }} cm</p>
            <p>Peso: {{ empaque.weigth }} kg</p>
            <span class="text-dagblue font-bold"> Disponibles: {{ empaque.quantity }} </span>
          </div>
          
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button (click)="closeEmpaqueDialog()" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dagblue text-base font-medium text-white hover:bg-dagblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dagblue sm:ml-3 sm:w-auto sm:text-sm">
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isloading">
    <app-loader></app-loader>
</div>
