<app-sidebar></app-sidebar>

<div class="ml-64 mt-14 flex">
  <div class="w-4/5 p-4 overflow-y-auto h-screen">
    <h2 class="text-2xl font-bold mb-4">Servicios Disponibles</h2>
    

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <div *ngFor="let scan of scans" 
           (click)="openModal(scan)" 
           class="bg-white p-4 rounded shadow cursor-pointer hover:bg-gray-100">
    
        <h3 class="text-lg font-semibold">{{ scan.name || 'Sin nombre' }}</h3>
        
        <p class="text-sm">Código de barras almacenado: {{ scan.barcode || 'N/A' }}</p>
      </div>
    </div>
  </div>
</div>

<!-- Modal de escaneo -->
<div *ngIf="showModal" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
  <div class="bg-white p-6 rounded-lg max-w-md w-full">
    <h2 class="text-xl font-bold mb-4">Escanear código para {{selectedScan?.name}}</h2>
    
    <!-- Input de escaneo dentro del modal -->
    <label for="scanInput" class="block text-sm font-medium text-gray-700">Escanear o ingresar código de barras</label>
    <input [(ngModel)]="scanCode" id="scanInput" type="text" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="Ingrese o escanee el código" (keydown.enter)="confirmScan()" />

    <div class="flex justify-end mt-6">
      <button (click)="closeModal()" class="mr-4 px-6 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400 transition duration-300">Cancelar</button>
      <button (click)="confirmScan()" class="px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition duration-300">Aceptar</button>
    </div>
  </div>
</div>
