
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AddressService {  

  constructor(private http: HttpClient) {}

  saveAddress(address: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/addresses/`, address);
  }

  getAddresses(limit: number = 10000): Observable<any> {
    const params = { limit: limit.toString() };
    return this.http.get<any>(`${environment.apiUrl}/addresses/user`, { params });
  }
  
  
  
  
}