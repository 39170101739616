import { Component } from "@angular/core";

@Component({
  selector: "app-view-gavetas-log-page",
  templateUrl: "./view-gavetas-log-page.component.html", // Asegúrate de que esté en la misma carpeta
  styleUrls: ['./view-gavetas-log-page.component.scss']
})
export class ViewGavetasLogPageComponent {
  // Tu lógica aquí
}
