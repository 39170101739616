<app-sidebar></app-sidebar>
<div class="ml-64 mt-14 flex">
  <!-- Barra de categorías a la izquierda -->
  <div class="w-1/5 bg-gray-100 p-4 overflow-y-auto h-screen">
    <h2 class="text-xl font-bold mb-4">Categorías</h2>
    <ul>
      <li *ngFor="let category of categories" 
          (click)="selectCategory(category)"
          class="cursor-pointer p-2 hover:bg-gray-200 rounded text-sm uppercase"
          [ngClass]="{'bg-blue-200': selectedCategory === category}">
        {{category}}
      </li>
    </ul>
  </div>

  <!-- Área de tarjetas de servicios a la derecha -->
  <div class="w-4/5 p-4 overflow-y-auto h-screen">
    <h2 class="text-2xl font-bold mb-4">{{selectedCategory}}</h2>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <div *ngFor="let service of services[selectedCategory]" 
           class="bg-white p-4 rounded shadow">
        <img [src]="getURLImage(service)" [alt]="service.CarrierName" class="w-full h-32 object-contain mb-2">
        <h3 class="text-lg font-semibold">{{service.ProductName}}</h3>
        <p class="text-sm">{{getToolTip(service)}}</p>
        <p class="text-sm font-semibold mt-2" *ngIf="service.Amount !== '0'">Precio: ${{service.Amount}}</p>
        <p class="text-sm font-semibold mt-2" *ngIf="service.Amount === '0'">
          Monto mínimo: ${{service.AmountMin}} - Monto máximo: ${{service.AmountMax}}
        </p>
        <div class="flex justify-end">
          <button (click)="openPaymentModal(service)" 
              class="px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition duration-300">
            Pagar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Modal de pago -->
<div *ngIf="showPaymentModal && selectedService" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" id="receiptContent">
  <div *ngIf="showAlert" 
     [ngClass]="{'bg-green-500': isSuccess, 'bg-red-500': !isSuccess}"
     class="fixed bottom-5 right-5 text-white p-4 rounded-lg shadow-lg">
  {{alertMessage}}
</div>
<div class="bg-white p-6 rounded-lg max-w-md w-full relative">
  <!-- Botón para cerrar (Cruz) dentro del recuadro blanco -->
  <button (click)="cancelPayment()" class="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
    </svg>
  </button>

  <h2 class="text-xl font-bold mb-4">Pago {{selectedService.ProductName}}</h2>

  <!-- Mostrar desglose de pago -->
  <div class="mb-4" *ngIf="selectedService.Amount !== '0'">
    <p class="font-semibold">Monto original: ${{selectedPrice}}</p>
    <p class="font-semibold">Comisión aplicada: ${{commission}}</p>
    <p class="font-semibold">Total a pagar: ${{totalAmount}}</p>
  </div>

  <!-- Campo de monto si es un servicio con monto variable -->
  <div class="mb-4" *ngIf="selectedService.Amount === '0'">
    <label class="block text-sm font-medium text-gray-700">Monto a pagar</label>
    <input [(ngModel)]="selectedPrice" type="number" [min]="selectedService.AmountMin" [max]="selectedService.AmountMax" 
           class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
           placeholder="Ingrese el monto">
  </div>

  <!-- Referencia dinámica -->
  <ng-container *ngFor="let refKey of ['Reference1', 'Reference2']">
    <ng-container *ngIf="selectedService.ReferenceParameters[refKey] as ref">
      <div *ngIf="!isArray(ref)" class="mb-4">
        <label class="block text-sm font-medium text-gray-700">
          {{ref.ReferenceName}}
        </label>
        <input [(ngModel)]="referenceValues[refKey]" 
               type="text" 
               [placeholder]="ref.ToolTip" 
               class="mt-1 block w-full rounded-md p-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
               [attr.minlength]="ref.LengthMin"
               [attr.maxlength]="ref.LengthMax">
      </div>
      <div *ngIf="isArray(ref)" class="mb-4">
        <label class="block text-sm font-medium text-gray-700">
          {{ref[0].ReferenceName}}
        </label>
        <select [(ngModel)]="referenceValues[refKey]" 
                class="mt-1 block w-full rounded-md p-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
          <option value="">Seleccione una opción</option>
          <option *ngFor="let option of ref" [value]="option.LengthMin">
            {{option.ToolTip}} ({{option.LengthMin}} dígitos)
          </option>
        </select>
      </div>
    </ng-container>
  </ng-container>

  <div class="flex justify-end mt-6">
    <button (click)="cancelPayment()" class="mr-4 px-6 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400 transition duration-300">Cancelar</button>
    <button (click)="confirmPayment()" class="px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition duration-300">Aceptar</button>
  </div>

</div>

</div>

<!-- Alerta -->

