import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { QuotesService } from 'src/app/_services/quotes/quotes.service';
import { UserServiceService } from 'src/app/_services/users/user-service.service';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';
import { SharedDataService } from 'src/app/_services/shared-data.service';
import { catchError, finalize, of } from 'rxjs';

interface Country {
  name: string;
  code: string;
}

interface Paqueteria {
  proveedor: string;
  idServicio: string;
  nombre_servicio: string;
  tiempo_de_entrega: string;
  precio: string;
  precioConComision: string;
}

interface PackingItem {
  packing_id: {
    _id: string;
    name: string;
    sell_price: { $numberDecimal: string };
    weigth: number;
    height: number;
    width: number;
    length: number;
    imageUrl: string;
  };
  quantity: number;
}

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {
  @ViewChild('quoteForm') quoteForm!: NgForm;

  // Declaramos la propiedad countries
  countries: Country[] = [
    { name: 'Afganistán', code: 'AF' },
    { name: 'Albania', code: 'AL' },
    { name: 'Alemania', code: 'DE' },
    { name: 'Andorra', code: 'AD' },
    { name: 'Angola', code: 'AO' },
    { name: 'Argentina', code: 'AR' },
    { name: 'Australia', code: 'AU' },
    { name: 'Brasil', code: 'BR' },
    { name: 'Canadá', code: 'CA' },
    { name: 'China', code: 'CN' },
    { name: 'Colombia', code: 'CO' },
    { name: 'Corea del Sur', code: 'KR' },
    { name: 'Egipto', code: 'EG' },
    { name: 'España', code: 'ES' },
    { name: 'Estados Unidos', code: 'US' },
    { name: 'Francia', code: 'FR' },
    { name: 'India', code: 'IN' },
    { name: 'Japón', code: 'JP' },
    { name: 'México', code: 'MX' },
    { name: 'Reino Unido', code: 'GB' },
    { name: 'Rusia', code: 'RU' },
    { name: 'Sudáfrica', code: 'ZA' }
    // Agrega los países que necesites aquí
  ];

  showEmpaqueDialog = false;
  packages: any[] = [];
  selectedEmpaque: any;
  empaques: any[] = [];
  packing: any;

  selectedPaqueteria: any;
  paqueteriaSeleccionada: any;
  isloading = false;
  showCity = false;
  showValue = false;
  showType = false;
  cotizar = false;
  result: any[] = [];
  selectedOption = 'Nacional';
  isInsured = 'No asegurado';
  shippingType = 'Sobre';
  dagpacketTax: any;
  
  currentIndex = 0;
  visiblePackages: any[] = [];

  quote = {
    pais_origen: 'MX',
    pais_destino: 'MX',
    cp_origen: '',
    cp_destino: '',
    alto: 0,
    ancho: 0,
    largo: 0,
    peso: 0,
    seguro: 0,
    valor_declarado: 0
  };
  description: string = '';
  superenviosResult: any[] = [];
  fedexResult: any[] = [];
  paqueteexpressResult: any[] = [];
  dhlResult: any[] = [];
  isInternational: boolean = false;

  constructor(
    private shipmentService: ShipmentsService,
    private quoteService: QuotesService,
    private router: Router,
    private userService: UserServiceService,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit(): void {
    this.getUserPercentage();
    this.fetchPackingData();
    this.showEmpaqueDialog = true;
  }

  showBotInstructions = false;

  toggleBotInstructions() {
    this.showBotInstructions = !this.showBotInstructions;
  }

  private getUserPercentage(): void {
    this.userService.getUserPercentage().subscribe(
      (res) => {
        if (res.success) {
          this.dagpacketTax = res.data.$numberDecimal;
          console.log('Dagpacket Tax:', this.dagpacketTax);
        }
      }
    );
  }

  private fetchPackingData(): void {
    this.shipmentService.getPackingList().subscribe(
      (res: any) => {
        if (res.success && res.data && res.data.length > 0) {
          this.packages = res.data[0].inventory.map(this.mapPackingItem);
          this.updateVisiblePackages(); // Actualizar empaques visibles
        }
      }
    );
  }

  private mapPackingItem(item: PackingItem) {
    return {
      _id: item.packing_id._id,
      name: item.packing_id.name,
      price: parseFloat(item.packing_id.sell_price.$numberDecimal),
      weigth: item.packing_id.weigth,
      height: item.packing_id.height,
      width: item.packing_id.width,
      length: item.packing_id.length,
      image: item.packing_id.imageUrl,
      quantity: item.quantity,
      packing_id: item.packing_id
    };
  }

  private updateVisiblePackages(): void {
    this.visiblePackages = this.packages.slice(this.currentIndex, this.currentIndex + 2);
  }

  nextEmpaque(): void {
    if (this.currentIndex + 2 < this.packages.length) {
      this.currentIndex += 2;
      this.updateVisiblePackages();
    }
  }

  previousEmpaque(): void {
    if (this.currentIndex > 0) {
      this.currentIndex -= 2;
      this.updateVisiblePackages();
    }
  }

  selectEmpaque(empaque: any): void {
    this.selectedEmpaque = empaque;
    this.quote.alto = empaque.height;
    this.quote.ancho = empaque.width;
    this.quote.largo = empaque.length;
    this.quote.peso = empaque.weigth;
    this.shippingType = 'Paquete';
    this.showType = true;

    this.packing = {
      answer: 'Si',
      packing_id: empaque._id,
      packing_type: empaque.name,
      packing_cost: empaque.price.toString()
    };

    this.sharedDataService.updateShipmentData({
      packing: this.packing
    });

    this.showEmpaqueDialog = false;
  }

  closeEmpaqueDialog(): void {
    this.showEmpaqueDialog = false;
  }

  hasInsurance(): void {
    this.showValue = this.isInsured !== 'No asegurado';
  }

  getProveedorImagePath(proveedor: string): string {
    return `../../../../assets/paqueterias/${proveedor}.png`;
  }

  setSelectedPaqueteria(paqueteria: any): void {
    this.selectedPaqueteria = paqueteria;
  }

  getSelectedPaqueteria(): any {
    return this.selectedPaqueteria;
  }

  seleccionarPaqueteria(paqueteria: any): void {
    this.paqueteriaSeleccionada = paqueteria;
  
    let { alto, ancho, largo, peso } = this.quote;
  
    if (this.shippingType === 'Sobre') {
      [alto, ancho, largo, peso] = [1, 25, 30, 1];
    }
  
    const precioOriginal = parseFloat(paqueteria.precio_regular);
    const precioConComision = parseFloat(paqueteria.precio);
    const dagpacketProfit = precioConComision - precioOriginal;
  
    // Determinar el proveedor API correcto
    let apiProvider;
    if (this.superenviosResult.some(p => p.idServicio === paqueteria.idServicio)) {
      apiProvider = 'Superenvios';
    } else if (this.fedexResult.some(p => p.idServicio === paqueteria.idServicio)) {
      apiProvider = 'FedEx';
    } else if (this.paqueteexpressResult.some(p => p.idServicio === paqueteria.idServicio)) {
      apiProvider = 'PaqueteExpress';
    } else {
      apiProvider = 'Desconocido';
    }
  
    const datosEnvio = {
      paqueteria: {
        ...paqueteria,
        precioOriginal: precioOriginal,
        precioConComision: precioConComision
      },
      dimensiones: { alto, ancho, largo, peso },
      shippingType: this.shippingType,
      cp_origen: this.quote.cp_origen,
      cp_destino: this.quote.cp_destino,
      valor_declarado: this.quote.valor_declarado,
      pais_destino: this.quote.pais_destino,
      description: this.description,
      packing: this.packing,
      dagpacketProfit: dagpacketProfit,
      isInternational: this.isInternational,
      proveedor: paqueteria.proveedor,
      apiProvider: apiProvider
    };
  
    console.log('Datos de envío a pasar:', datosEnvio);
  
    this.sharedDataService.updateShipmentData({
      shipment_type: this.shippingType,
      provider: apiProvider,
      idService: paqueteria.idServicio,
      cost: paqueteria.precio,
      price: paqueteria.precioConComision,
      shipment_data: {
        height: alto,
        width: ancho,
        length: largo,
        package_weight: peso,
      },
      paqueteria_details: {
        ...paqueteria,
        proveedor: paqueteria.proveedor
      },
      description: this.description,
      isInternational: this.isInternational,
      dagpacket_profit: dagpacketProfit
    });
  
    this.router.navigate(['/address'], {
      queryParams: { datosEnvio: JSON.stringify(datosEnvio) }
    });
  }

  shipmentType(): void {
    this.showType = this.shippingType !== 'Sobre';
    if (this.shippingType === 'Sobre') {
      this.quote.alto = 1;
      this.quote.ancho = 25;
      this.quote.largo = 30;
      this.quote.peso = 1;
    } else {
      this.quote.alto = this.quote.ancho = this.quote.largo = this.quote.peso = 0;
    }
  }

  onOptionChange(): void {
    this.showCity = this.selectedOption !== 'Nacional';
    if (this.selectedOption === 'Nacional') {
      this.quote.pais_destino = 'MX';
    } else {
      this.quote.pais_destino = '';
    }
  }

  cotizarEnvio(quoteForm: NgForm): void {
    const formData = {
      ...this.quote,
      ...quoteForm.value
    };
  
    if (formData.seguro === 'No asegurado') {
      formData.valor_declarado = '0';
    }
  
    if (this.shippingType === 'Sobre') {
      [formData.alto, formData.ancho, formData.largo, formData.peso] = [24, 25, 34, 3];
    }
    this.isInternational = this.selectedOption === 'Internacional';
    console.log('Datos de cotización:', formData);
  
    this.isloading = true;
    this.cotizar = false;
  
    this.quoteService.getQuote(formData).pipe(
      finalize(() => {
        this.isloading = false;
        this.cotizar = true;
      }),
      catchError(error => {
        console.error('Error al obtener cotizaciones:', error);
        return of({ superenvios: { paqueterias: [] }, fedex: [], paqueteexpress: [], dhl: [] });
      })
    ).subscribe(
      (response) => {
        this.superenviosResult = response.superenvios?.paqueterias || [];
        console.log('Resultados SuperEnvíos:', this.superenviosResult);
  
        this.fedexResult = response.fedex || [];
        console.log('Resultados FedEx:', this.fedexResult);
  
        this.paqueteexpressResult = response.paqueteexpress || [];
        console.log('Resultados Paquete Express:', this.paqueteexpressResult);
  
        this.dhlResult = response.dhl || [];
        console.log('Resultados DHL:', this.dhlResult);
  
        if (this.superenviosResult.length === 0) {
          console.log('No se obtuvieron resultados de SuperEnvíos');
        }
        if (this.fedexResult.length === 0) {
          console.log('No se obtuvieron resultados de FedEx');
        }
        if (this.paqueteexpressResult.length === 0) {
          console.log('No se obtuvieron resultados de Paquete Express');
        }
        if (this.dhlResult.length === 0) {
          console.log('No se obtuvieron resultados de DHL');
        }
  
        if (this.superenviosResult.length === 0 && this.fedexResult.length === 0 && this.paqueteexpressResult.length === 0 && this.dhlResult.length === 0) {
          console.log('No se obtuvieron cotizaciones de ningún proveedor');
        }
      }
    );
  }


  resetCotizacion(): void {
   this. quote = {
      pais_origen: 'MX',
      pais_destino: 'MX',
      cp_origen: '',
      cp_destino: '',
      alto: 0,  // Inicializar en 0
      ancho: 0, // Inicializar en 0
      largo: 0, // Inicializar en 0
      peso: 0,  // Inicializar en 0
      seguro: 0,
      valor_declarado: 0
    };
    
    

    this.description = '';
    this.selectedOption = 'Nacional';
    this.isInsured = 'No asegurado';
    this.shippingType = 'Sobre';
    this.showType = false;
    this.showValue = false;
    this.showCity = false;

    this.superenviosResult = [];
    this.fedexResult = [];

    this.cotizar = false;
    this.isloading = false;

    window.scrollTo(0, 0);
  }
}
