import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GavetaService } from 'src/app/_services/gavetas/gavetas.service';

@Component({
  selector: 'app-gabetas-details',
  templateUrl: './gabetas-details.component.html',
  styleUrls: ['./gabetas-details.component.scss']
})
export class GabetasDetailsComponent implements OnInit {
  gaveta: any; // Aquí se almacenarán los detalles de la gaveta
  id_gabeta: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private gavetaService: GavetaService
  ) {}

  ngOnInit(): void {
    // Obtener el parámetro 'id_gabeta' de la ruta
    this.id_gabeta = this.route.snapshot.paramMap.get('id_gabeta');
    
    // Verificar que el ID no sea nulo antes de llamar al servicio
    if (this.id_gabeta) {
      // Llama al servicio para obtener los detalles de la gaveta
      this.gavetaService.getGavetasByLocker(this.id_gabeta).subscribe(
        (response) => {
          // Asignar los datos de la respuesta directamente al objeto gaveta
          this.gaveta = response;
          console.log('Datos de la gaveta recibidos:', this.gaveta);
        },
        (error) => {
          // Manejo de errores
          console.error('Error al obtener los detalles de la gaveta:', error);
        }
      );
    } else {
      console.error('El ID de la gaveta es nulo.');
    }
  }
}
