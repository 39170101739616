import { Component, OnInit, ViewChild, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedDataService } from 'src/app/_services/shared-data.service';
import { GoogleMapsService } from 'src/app/_services/google/google-maps.service';
import { Subscription } from 'rxjs';
import { AddressService } from 'src/app/_services/Address/address.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-from-to',
  templateUrl: './from-to.component.html',
  styleUrls: ['./from-to.component.scss']
})
export class FromToComponent implements OnInit, OnDestroy {
  @ViewChild('fromForm') fromForm!: NgForm;  // Referencia para el formulario del remitente
  @ViewChild('toForm') toForm!: NgForm;      // Referencia para el formulario del destinatario
  @ViewChild('fromAddressInput') fromAddressInput!: ElementRef;
  @ViewChild('toAddressInput') toAddressInput!: ElementRef;

  shipmentData: any = {};
  searchTerm: string = '';
  filteredAddresses: any[] = [];
  
  isAddressDialogOpen = false;
  savedAddresses: any[] = [];
  selectedFormType: 'remitente' | 'destinatario' = 'remitente';

  from: any = {
    name: '', phone: '', email: '', street: '', city: '', state: '', country: '', 
    country_code: 'MX', settlement: '', zip_code: '', municipality: '', 
    external_number: '', internal_number: '', reference: '', rfc: '',
    clave_localidad: '', clave_municipio: '', iso_estado: '', iso_pais: '', 
    clave_colonia: ''
  };
  
  to: any = {
    name: '', phone: '', email: '', street: '', city: '', state: '', country: '', 
    country_code: 'MX', settlement: '', zip_code: '', municipality: '', 
    external_number: '', internal_number: '', reference: '', rfc: '',
    clave_localidad: '', clave_municipio: '', iso_estado: '', iso_pais: '', 
    clave_colonia: ''
  };

  private subscriptions: Subscription[] = [];

  stateCodeMap: { [key: string]: string } = {
    'Jalisco': 'JA',
    'Aguascalientes': 'AG',
    'Baja California': 'BC',
    'Baja California Sur': 'BS',
    'Campeche': 'CM',
    'Chiapas': 'CS',
    'Chihuahua': 'CH',
    'Coahuila': 'CO',
    'Colima': 'CL',
    'Ciudad de México': 'DF',
    'Durango': 'DG',
    'Guanajuato': 'GT',
    'Guerrero': 'GR',
    'Hidalgo': 'HG',
    'Estado de México': 'EM',
    'Michoacán': 'MI',
    'Morelos': 'MO',
    'Nayarit': 'NA',
    'Nuevo León': 'NL',
    'Oaxaca': 'OA',
    'Puebla': 'PU',
    'Querétaro': 'QE',
    'Quintana Roo': 'QR',
    'San Luis Potosí': 'SL',
    'Sinaloa': 'SI',
    'Sonora': 'SO',
    'Tabasco': 'TB',
    'Tamaulipas': 'TM',
    'Tlaxcala': 'TL',
    'Veracruz': 'VE',
    'Yucatán': 'YU',
    'Zacatecas': 'ZA'
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sharedDataService: SharedDataService,
    private googleMapsService: GoogleMapsService,  
    private addressService: AddressService,  
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    // Cargar direcciones guardadas desde localStorage si existen
    const storedFromData = localStorage.getItem('fromData');
    const storedToData = localStorage.getItem('toData');

    if (storedFromData) {
      this.from = JSON.parse(storedFromData);
    }
    
    if (storedToData) {
      this.to = JSON.parse(storedToData);
    }

    this.loadSavedAddresses();
    this.processRouteParams();
  }

  showBotInstructions = false;

  toggleBotInstructions() {
    this.showBotInstructions = !this.showBotInstructions;
  }

  ngAfterViewInit() {
    this.setupAutocomplete();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private processRouteParams() {
    const subscription = this.route.queryParams.subscribe(params => {
      if (params['datosEnvio']) {
        try {
          this.shipmentData = JSON.parse(params['datosEnvio']);
          this.from.zip_code = this.shipmentData.cp_origen;
          this.to.zip_code = this.shipmentData.cp_destino;
        } catch (error) {
          console.error('Error al parsear los datos de envío:', error);
        }
      }
    });
    this.subscriptions.push(subscription);
  }

  saveAddress(type: 'remitente' | 'destinatario', form: NgForm) {
    if (form.valid) {
      const addressToSave = type === 'remitente' ? this.from : this.to;
      addressToSave.type = type;

      // Guardar la dirección si es válida
      this.addressService.saveAddress(addressToSave).subscribe(
        (response) => {
          console.log(`Dirección de ${type} guardada con éxito:`, response);
          this.loadSavedAddresses();
        },
        (error) => {
          console.error(`Error al guardar la dirección de ${type}:`, error);
        }
      );
    } else {
      console.error(`El formulario de ${type} tiene errores de validación.`);
      form.control.markAllAsTouched();  // Marcar todos los campos inválidos
    }
  }

  filterAddresses() {
    const searchTermLower = this.searchTerm.toLowerCase().trim();
    this.filteredAddresses = this.savedAddresses.filter(address =>
      address.name.toLowerCase().includes(searchTermLower) ||
      address.street.toLowerCase().includes(searchTermLower) ||
      address.city.toLowerCase().includes(searchTermLower) ||
      address.zip_code.includes(searchTermLower)
    );
  }

  loadSavedAddresses() {
    this.addressService.getAddresses().subscribe(
      (response) => {
        this.savedAddresses = response.data;
      },
      (error) => {
        console.error('Error al cargar las direcciones guardadas:', error);
      }
    );
  }

  openAddressDialog(type: 'remitente' | 'destinatario') {
    this.selectedFormType = type;
    this.isAddressDialogOpen = true;
  }

  closeAddressDialog() {
    this.isAddressDialogOpen = false;
  }

  selectAddress(address: any) {
    const target = this.selectedFormType === 'remitente' ? this.from : this.to;
    Object.assign(target, address);
    this.closeAddressDialog();
  }

  setupAutocomplete(): void {
    const fromAutocomplete = new google.maps.places.Autocomplete(this.fromAddressInput.nativeElement, {
      types: ['address'],
      componentRestrictions: { country: 'MX' }
    });
    const toAutocomplete = new google.maps.places.Autocomplete(this.toAddressInput.nativeElement, {
      types: ['address'],
      componentRestrictions: { country: 'MX' }
    });

    fromAutocomplete.addListener('place_changed', () => this.fillAddressFields(fromAutocomplete, 'from'));
    toAutocomplete.addListener('place_changed', () => this.fillAddressFields(toAutocomplete, 'to'));
  }

  fillAddressFields(autocomplete: google.maps.places.Autocomplete, formType: 'from' | 'to'): void {
    this.ngZone.run(() => {
      const place = autocomplete.getPlace();
      if (place.address_components) {
        const target = formType === 'from' ? this.from : this.to;
        target.street = place.formatted_address;
        this.fillMexicanSpecificFields(target, place);
        target.full_address = place.formatted_address;
      }
    });
  }

  fillMexicanSpecificFields(target: any, place: google.maps.places.PlaceResult): void {
    place.address_components?.forEach((component: google.maps.GeocoderAddressComponent) => {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number':
          target.external_number = component.long_name;
          break;
        case 'route':
          target.street = component.long_name;
          break;
        case 'sublocality_level_1':
        case 'locality':
          target.city = component.long_name;
          break;
        case 'administrative_area_level_1':
          target.state = component.long_name;
          target.iso_estado = this.stateCodeMap[component.long_name] || component.short_name;
          break;
        case 'country':
          target.country = 'México';
          target.iso_pais = 'MX';
          break;
        case 'postal_code':
          target.zip_code = component.long_name;
          break;
      }
    });

    target.settlement = place.address_components?.find(
      component => component.types.includes('sublocality_level_1') || component.types.includes('neighborhood')
    )?.long_name || '';

    target.municipality = place.address_components?.find(
      component => component.types.includes('administrative_area_level_2')
    )?.long_name || '';

    target.country_code = 'MX';

    console.log(`Dirección actualizada:`, target);
  }

  onContinue() {
    if (this.fromForm.valid && this.toForm.valid) {
      // Guardar los datos en localStorage nuevamente
      localStorage.setItem('fromData', JSON.stringify(this.from));
      localStorage.setItem('toData', JSON.stringify(this.to));
      
      // Proceder si ambos formularios son válidos
      const updatedShipmentData = {
        ...this.shipmentData,
        from: this.from,
        to: this.to
      };
  
      this.sharedDataService.updateShipmentData(updatedShipmentData);
      this.router.navigate(['/checkout'], { 
        queryParams: { datosEnvio: JSON.stringify(updatedShipmentData) } 
      });
    } else {
      // Marcar todos los campos como tocados para mostrar los errores
      this.fromForm.control.markAllAsTouched();
      this.toForm.control.markAllAsTouched();
    }
  }
  
  

  clearStoredData() {
    localStorage.removeItem('fromData');
    localStorage.removeItem('toData');
  }

  clearForm() {
    this.clearStoredData();  // Limpia los datos almacenados
    const emptyForm = {
      name: '', phone: '', email: '', street: '', city: '', state: '', country: '', 
      country_code: 'MX', settlement: '', zip_code: '', municipality: '', 
      external_number: '', internal_number: '', reference: '', rfc: '',
      clave_localidad: '', clave_municipio: '', iso_estado: '', iso_pais: '', 
      clave_colonia: ''
    };

    if (this.selectedFormType === 'remitente') {
      this.from = { ...emptyForm };
    } else {
      this.to = { ...emptyForm };
    }
  }
}
