import { Component } from '@angular/core';
import { ScanService } from 'src/app/_services/Scan/scan.service';  

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html'
})
export class ScanComponent {
  scans: any[] = [];  
  showModal: boolean = false;  
  selectedScan: any = null;  
  scanCode: string = '';  

  constructor(private scanService: ScanService) {
    this.loadScans();  
  }

  loadScans() {
    this.scanService.getScans().subscribe({
      next: (response: any) => {
        this.scans = response.data;  
      },
      error: (error) => {
        console.error('Error al cargar los escaneos', error);
      }
    });
  }

  openModal(scan: any) {
    this.selectedScan = scan;
    this.showModal = true;
    this.scanCode = ''; 
  }
  
  closeModal() {
    this.showModal = false;
    this.scanCode = '';  
  }

  
  confirmScan() {
    if (this.scanCode.length === 18) {
      this.scanService.updateBarcode(this.selectedScan._id, this.scanCode).subscribe({
        next: (response) => {
          console.log('Código de barras actualizado:', response);
          this.closeModal();  
        },
        error: (error) => {
          console.error('Error al actualizar el código de barras:', error);
        }
      });
    } else {
      alert('El código de barras debe tener 18 caracteres.');
    }
  }
}
