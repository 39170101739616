import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Users } from 'src/app/_models/user-model';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { filter } from 'rxjs/operators';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('sectionAnimation', [
      state('closed', style({
        height: '0',
        opacity: '0',
        overflow: 'hidden'
      })),
      state('open', style({
        height: '*',
        opacity: '1'
      })),
      transition('closed <=> open', animate('300ms ease-in-out'))
    ])
  ]
})
export class SidebarComponent implements OnInit {
  openSections: { [key: string]: boolean } = {};
  user!: Users;
  currentPage: string;
  id!: any;
  shipmentBalance: number = 0;
  servicesBalance: number = 0;
  recharguesBalance: number = 0;
  isCartOpen = false;
  cartItems: any[] = [];

  constructor(
    private authService: AuthLoginService,
    private router: Router,
    private shipmentService: ShipmentsService
  ) {
    this.currentPage = this.router.url;
  }

  ngOnInit(): void {
    const savedState = localStorage.getItem('sidebarState');
    if (savedState) {
      this.openSections = JSON.parse(savedState);
    }

    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentPage = event.url;
      this.saveSectionState();
    });

    this.authService.userProfile().subscribe(
      (res) => {
        this.user = res.data;
        if (this.user.wallet) {
          this.shipmentBalance = parseFloat(this.user.wallet.sendBalance.$numberDecimal) || 0;
          this.servicesBalance = parseFloat(this.user.wallet.servicesBalance.$numberDecimal) || 0;
          this.recharguesBalance = parseFloat(this.user.wallet.rechargeBalance.$numberDecimal) || 0;
        }
      }
    );

    this.id = this.authService.getId();
    this.loadItems();
  }

  toggleSection(section: string): void {
    this.openSections[section] = !this.openSections[section];
    this.saveSectionState();
  }

  isSectionOpen(section: string): boolean {
    return this.openSections[section] || false;
  }

  saveSectionState() {
    localStorage.setItem('sidebarState', JSON.stringify(this.openSections));
  }

  isCurrentPage(route: string): boolean {
    return this.currentPage.includes(route);
  }

  logout() {
    console.log('Logout button clicked');
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  openNotifications() {
    console.log('Presionado');
  }

  toggleCart() {
    this.isCartOpen = !this.isCartOpen;
  }

  hasRole(role: string): boolean {
    return this.user.role === role;
  }

  openCart(): void {
    this.isCartOpen = true;
  }

  closeCart(): void {
    this.isCartOpen = false;
  }

  handleCartClick(): void {
    this.openCart();
  }

  removeItem(item: any) {
    const index = this.cartItems.indexOf(item);
    if (index > -1) {
      this.cartItems.splice(index, 1);
    }
  }

  getItemPrice(item: any): number {
    return parseFloat(item.price.$numberDecimal) || 0;
  }

  getTotalPrice(): number {
    return this.cartItems.reduce((total, item) => {
      return total + this.getItemPrice(item);
    }, 0);
  }

  loadItems() {
    this.shipmentService.getPendingShipments().subscribe(
      res => {
        this.cartItems = res.data;
      },
      error => {
        console.log('Error al cargar los envíos pendientes:', error);
        this.cartItems = [];
      }
    );
  }
}