import { Component } from '@angular/core';

interface Panel {
  id: number;
  name: string;
  hasAd: boolean;
  images: FileWithPreview[];
  currentIndex: number;
  isEditing: boolean;
  selectedImage1?: string;
  selectedImage2?: string;
}

interface FileWithPreview {
  file: File | null;
  preview: string;
}

@Component({
  selector: 'app-publicidad',
  templateUrl: './publicidad.component.html',
  styleUrls: ['./publicidad.component.scss']
})

export class PublicidadComponent {
  isModalOpen = false;
  currentPanel: Panel | null = null;
  intervaloTiempo: number = 20;
  ingresosPorAnuncio: number = 0;
  contratoPDF: File | null = null;
  tempImages: FileWithPreview[] = [];

  panels: Panel[] = [
    { id: 1, name: 'Minerva', hasAd: true, images: [
      { file: null, preview: 'https://www.inboundcycle.com/hs-fs/hubfs/anuncios%20publicitarios%20informativos.jpg?width=6400&height=3598&name=anuncios%20publicitarios%20informativos.jpg' },
      { file: null, preview: 'https://1.bp.blogspot.com/-THcOVRAvxRc/Vxb_28DjOCI/AAAAAAAAADM/BJF5JGeEd-gDsy1RewF1YmUAbL6ef2NvwCLcB/s1600/gppp-agosto10.jpg' }
    ], currentIndex: 0, isEditing: false },
    { id: 2, name: 'Plaza del Sol', hasAd: true, images: [
      { file: null, preview: 'https://www.caracteristicasdel.com/wp-content/uploads/2022/05/Caracteristicas-del-Anuncio-Publicitario..-768x620.png' }
    ], currentIndex: 0, isEditing: false },
    { id: 3, name: 'Plaza del Sol', hasAd: false, images: [], currentIndex: 0, isEditing: false },
    { id: 4, name: 'Andares', hasAd: false, images: [], currentIndex: 0, isEditing: false },
    { id: 5, name: 'Valle Real', hasAd: false, images: [], currentIndex: 0, isEditing: false },
    { id: 6, name: 'Minerva', hasAd: true, images: [], currentIndex: 0, isEditing: false },
  ];

  openModal(panelName: string) {
    this.currentPanel = this.panels.find(panel => panel.name === panelName) || null;
    this.isModalOpen = true;
    this.tempImages = [];
  }

  hasDigitalAds(panel: Panel): boolean {
    return panel.images.length > 0;
  }

  hasPrintAds(panel: Panel): boolean {
    return !!panel.selectedImage1 && !!panel.selectedImage2;
  }

  hasAnyAds(panel: Panel): boolean {
    return this.hasDigitalAds(panel) || this.hasPrintAds(panel);
  }

  closeModal() {
    this.isModalOpen = false;
    this.intervaloTiempo = 20;
    this.ingresosPorAnuncio = 0;
    this.contratoPDF = null;
    this.tempImages.forEach(fileWithPreview => URL.revokeObjectURL(fileWithPreview.preview));
    this.tempImages = [];
    this.currentPanel = null;
  }

  onFileSelected(event: any) {
    const files: FileList = event.target.files;
    const newFiles = Array.from(files).slice(0, 10 - this.tempImages.length).map(file => ({
      file,
      preview: URL.createObjectURL(file)
    }));
    this.tempImages = [...this.tempImages, ...newFiles];
  }

  onPDFSelected(event: any) {
    const file: File = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      this.contratoPDF = file;
    } else {
      alert('Por favor, selecciona un archivo PDF válido.');
    }
  }

  prevImage(panelId: number) {
    const panel = this.panels.find(p => p.id === panelId);
    if (panel) {
      panel.currentIndex = (panel.currentIndex > 0) ? panel.currentIndex - 1 : panel.images.length - 1;
    }
  }

  nextImage(panelId: number) {
    const panel = this.panels.find(p => p.id === panelId);
    if (panel) {
      panel.currentIndex = (panel.currentIndex < panel.images.length - 1) ? panel.currentIndex + 1 : 0;
    }
  }

  getTransform(panelId: number): string {
    const panel = this.panels.find(p => p.id === panelId);
    return panel ? `translateX(-${panel.currentIndex * 100}%)` : 'translateX(0)';
  }

  toggleEditMode(panelId: number) {
    const panel = this.panels.find(p => p.id === panelId);
    if (panel) {
      panel.isEditing = !panel.isEditing;
    }
  }

  removeImage(panelId: number, index: number) {
    const panel = this.panels.find(p => p.id === panelId);
    if (panel) {
      URL.revokeObjectURL(panel.images[index].preview);
      panel.images.splice(index, 1);
      if (panel.currentIndex >= panel.images.length) {
        panel.currentIndex = panel.images.length - 1;
      }
    }
  }

  guardarPublicidad() {
    if (this.currentPanel && this.tempImages.length > 0 && this.intervaloTiempo >= 20 && this.intervaloTiempo <= 30 && this.contratoPDF) {
      console.log('Guardando publicidad para el panel:', this.currentPanel.name);
      console.log('Archivos seleccionados:', this.tempImages.map(f => f.file));
      console.log('Intervalo de tiempo:', this.intervaloTiempo);
      console.log('Ingresos por anuncio:', this.ingresosPorAnuncio);
      console.log('Contrato PDF:', this.contratoPDF);
      
      this.currentPanel.images = [...this.currentPanel.images, ...this.tempImages];
      this.tempImages = [];

      this.closeModal();
    } else {
      alert('Por favor, selecciona al menos una imagen, define un intervalo de tiempo válido y adjunta un contrato en PDF.');
    }
  }

  ngOnDestroy() {
    this.panels.forEach(panel => {
      panel.images.forEach(fileWithPreview => URL.revokeObjectURL(fileWithPreview.preview));
    });
  }

  removeTempImage(index: number) {
    URL.revokeObjectURL(this.tempImages[index].preview);
    this.tempImages.splice(index, 1);
  }

  onImageSelected(event: Event, panelId: number, imageNumber: number): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const panel = this.panels.find(p => p.id === panelId);
        if (panel) {
          if (imageNumber === 1) {
            panel.selectedImage1 = e.target.result;
          } else if (imageNumber === 2) {
            panel.selectedImage2 = e.target.result;
          }
        }
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  removeSelectedImage(panelId: number, imageNumber: number): void {
    const panel = this.panels.find(p => p.id === panelId);
    if (panel) {
      if (imageNumber === 1) {
        panel.selectedImage1 = undefined;
      } else if (imageNumber === 2) {
        panel.selectedImage2 = undefined;
      }
    }
  }
}