import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment.prod";

@Injectable({
    providedIn: "root",
})

export class MqttService {
    constructor(private http: HttpClient) {}

    openDoor(lockerId: String, gavetaId: String, action: String = "sendLocker"): Observable<any> {
        return this.http.post(`${environment.apiUrl}/mqtt/`, {
            locker_id: String(lockerId),
            action,
            gabeta: String(gavetaId),
        });
    }
   



}    