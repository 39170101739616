import { Component, OnInit } from '@angular/core';
import { LockersService } from 'src/app/_services/Lockers/lockers.service';
import { Lockers } from 'src/app/_models/lockers';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-admin-lockers',
  templateUrl: './admin-lockers.component.html',
  styleUrls: ['./admin-lockers.component.scss'],
})
export class AdminLockersComponent implements OnInit {
  lockers: Lockers[] = [];
  enteredPin: string = '';  
  storedPin: string = '';   
  showPinModal: boolean = false;  
  showAddLocker: boolean = false; 

  newLocker: Lockers = {
    _id: '',
    id_locker: '',
    ubication: '',
    package: '',
    quant_gabetas: 0,
    lat: 0,
    long: 0,
    status: true,
    capacity: 0,
    saturation: 0,
    street: '',
    cp: '',
    city: '',
    state: '',
    country: '',
    num_ext: 0,
  };

  constructor(private lockerService: LockersService, private authService: AuthLoginService) {}

  ngOnInit(): void {
    this.getLockers();  
    this.getUserPin();  
  }


  openAddLocker() {
    this.enteredPin = '';
    this.showPinModal = true; 
  }


  closePinModal(): void {
    this.showPinModal = false;
    this.enteredPin = '';  
  }


  closeAddLocker(): void {
    this.showAddLocker = false;  
  }

  validateNip(): void {
    if (this.enteredPin === this.storedPin) {
      this.showPinModal = false;  
      this.showAddLocker = true;  
    } else {
      Swal.fire({
        icon: 'error',
        title: 'NIP incorrecto',
        text: 'El NIP ingresado no es correcto.',
      });
    }
  }

  getUserPin(): void {
    this.authService.userProfile().subscribe(
      (res) => {
        this.storedPin = res.data.pin;  
      },
      (error) => {
        console.log('Error al obtener el perfil del usuario', error);
      }
    );
  }

  addLocker(form: NgForm): void {
    if (form.invalid) {
      Swal.fire({
        icon: 'error',
        title: 'Error al añadir el locker',
        text: 'Por favor, llena todos los campos requeridos',
      });
      return;
    }

    this.lockerService.createLocker(this.newLocker).subscribe(
      (data: any) => {
        Swal.fire({
          icon: 'success',
          title: 'Locker añadido',
          text: 'Locker añadido correctamente',
        });
        this.showAddLocker = false; 
        this.getLockers();  
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Error al añadir el locker',
          text: 'Por favor, intenta de nuevo',
        });
      }
    );
  }

  getLockers(): void {
    this.lockerService.getLockers().subscribe(
      (data: any) => {
        this.lockers = data.message.map((locker: any) => ({
          _id: locker._id,
          id_locker: locker.id_locker,
          ubication: locker.ubication,
          package: locker.package,
          quant_gabetas: locker.quant_gabetas,
          lat: locker.lat,
          long: locker.long,
          status: locker.status,
          capacity: locker.capacity,
          saturation: locker.saturation,
          street: locker.street,
          cp: locker.cp,
          city: locker.city,
          state: locker.state,
          country: locker.country,
          num_ext: locker.num_ext,
        }));
      },
      (error) => {
        console.log('Error al obtener los lockers', error);
      }
    );
  }
}
