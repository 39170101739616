import { Component, OnInit } from '@angular/core';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';

@Component({
  selector: 'app-shipments-sended',
  templateUrl: './shipments-sended.component.html',
  styleUrls: ['./shipments-sended.component.scss']
})
export class ShipmentsSendedComponent implements OnInit {
  shipments: any[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalItems: number = 0;
  totalPages: number = 1;
  sortBy: string = 'createdAt';
  sortOrder: 'asc' | 'desc' = 'desc';
  searchTerm: string = '';
  Math = Math;

  constructor(private shipmentsService: ShipmentsService) {}

  ngOnInit(): void {
    this.loadShipments();
  }

  loadShipments(): void {
    this.shipmentsService.getUserShipments(
      this.currentPage,
      this.itemsPerPage,
      this.sortBy,
      this.sortOrder
    ).subscribe(
      (response: any) => {
        if (response.success) {
          this.shipments = response.data.shipments;
          this.totalItems = response.data.totalShipments;
          this.totalPages = response.data.totalPages;
          this.currentPage = response.data.currentPage;
          console.log('Utilidad', response.data.shipments.utilitie_lic.$numberDecimal);
          
        } else {
          console.error('Error loading shipments:', response.message);
        }
      },
      error => {
        console.error('Error loading shipments:', error);
      }
    );
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.loadShipments();
  }

  onSort(column: string): void {
    if (this.sortBy === column) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortBy = column;
      this.sortOrder = 'asc';
    }
    this.loadShipments();
  }

  onSearch(): void {
    this.currentPage = 1;
    this.loadShipments();
  }
}