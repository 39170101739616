<app-sidebar></app-sidebar>
<div class="ml-64 mt-14">
  <div class="p-6">
    <div class="font-bold text-dagblue">
      <div class="flex justify-between gap-2 items-center">
        <div *ngIf="lockerDetails" class="flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
            />
          </svg>
          <span> {{ lockerDetails.ubication }} </span>
        </div>
        <div>
          <!-- toggle-switch.component.html -->
          <label class="toggle-switch">
            <input
              type="checkbox"
              [checked]="isChecked"
              (change)="onToggle()"
            />
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>
    <div class="mt-6 w-full">
      <div class="flex justify-between items-center">
        <div class="flex flex-col">
          <!-- <span class="text-dagpk font-bold"> Dimensiones </span> -->
          <!-- <span class="font-bold"> Ultima actualizacion hace 2 meses </span> -->
          <div
            *ngIf="user && user.user; else noUser"
            class="max-w-sm mx-auto bg-white border border-gray-200 rounded-lg shadow-md p-2"
          >
            <div class=" ">
              <h3>Dueño del Locker</h3>
              <h2 class="text-lg font-semibold text-gray-800">
                {{ user.user.name }} {{ user.user.surname }}
              </h2>
              <p>
                Rol: <strong>{{ user.user.role }}</strong>
              </p>
              <p class="ext-gray-600">
                Email: <strong>{{ user.user.email }}</strong>
              </p>
              <p class="mt-2 text-gray-600">
                Telefono: <strong>{{ user.user.phone }}</strong>
              </p>
            </div>
            <div class="p-4">
              <button
                (click)="updatePassword()"
                class="bg-dagpk text-white rounded-md p-2"
              >
                Generar y actualizar contraseña
              </button>
            </div>
          </div>

          <ng-template #noUser>
            <div class="text-center py-4">
              <p class="text-lg text-gray-500">No hay usuario asignado.</p>
            </div>
          </ng-template>
        </div>
      </div>

      <div class="flex justify-between gap-4 pt-6">
        <div class="flex justify-start gap-4">
          <button
          (click)="openChangeOwner()"
          *ngIf="user && user.user"
          class="flex rounded-lg text-white bg-dagblue p-2"
        >
          Editar locker
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
            />
          </svg>
        </button>
          <button
            (click)="openChangeOwner()"
            *ngIf="user && user.user"
            class="flex rounded-lg text-white bg-dagblue p-2"
          >
            Cambiar dueño
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
              />
            </svg>
          </button>
          <button
            (click)="openAddOwner()"
            *ngIf="user && !user.user"
            class="flex rounded-lg text-white bg-dagblue p-2"
          >
            Asignar dueño
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
              />
            </svg>
          </button>
          <button
            (click)="openAddGaveta()"
            class="flex rounded-lg text-white bg-dagblue p-2"
          >
            Crear gaveta
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
              />
            </svg>
          </button>
          <button
            (click)="updateLockerStatus(!lockerDetails.status)"
            class="{{
              lockerDetails.status ? 'bg-red-500' : 'bg-emerald-500'
            }} rounded-md rounded-lg text-white p-1"
          >
            {{ lockerDetails.status ? "Desactivar Locker" : "Activar Locker" }}
          </button>

          <button
            *ngIf="selectedGavetasIds.length > 0"
            (click)="deleteSelectedGavetas()"
            class="bg-red-600 text-white rounded-lg py-2 px-1"
          >
            Eliminar Seleccionadas
          </button>
        </div>

        <div class="flex justify-end items-center">
          <select
            name="statusFilter"
            id="statusFilter"
            class="p-2 border-2 rounded-lg w-52"
            (change)="onFilterChange($event)"
          >
            <option value="all">Selecciona una opción</option>
            <option value="all">Todos</option>
            <option value="available">Disponibles</option>
            <option value="occupied">Ocupadas</option>
          </select>
        </div>
      </div>

      <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead class="text-xs uppercase bg-dagblue text-white">
            <tr>
              <th scope="col" class="px-6 py-3">
                <input
                  type="checkbox"
                  [(ngModel)]="allSelected"
                  (change)="selectAll($event)"
                />
              </th>
              <th scope="col" class="px-6 py-3">Cajón</th>
              <th scope="col" class="px-6 py-3">Tipo</th>
              <th scope="col" class="px-6 py-3">Tamaño</th>
              <th scope="col" class="px-6 py-3">Estatus</th>
              <th scope="col" class="px-6 py-3">Disponibilidad</th>

              <th scope="col" class="px-6 py-3">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let gaveta of filteredGavetas"
              class="bg-white border-b hover:bg-gray-50"
            >
              <td class="px-6 py-4">
                <input
                  type="checkbox"
                  [(ngModel)]="gaveta.selected"
                  [value]="gaveta.selected"
                  (change)="toggleGavetaSelection(gaveta, $event)"
                />
              </td>
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
              >
                {{ gaveta.id_gabeta }}
              </th>
              <td class="px-6 py-4">
                {{
                  gaveta.type == ""
                    ? "No se ha definido el tipo de la gaveta"
                    : gaveta.type
                }}
              </td>
              <td class="px-6 py-4">
                {{
                  gaveta.size == ""
                    ? "No se ha definido el tamaño de la gaveta"
                    : gaveta.size
                }}
              </td>

              <td class="px-6 py-4">
                <span
                  class="{{
                    gaveta.status
                      ? 'text-emerald-700 bg-emerald-100'
                      : 'text-red-700 bg-red-100'
                  }} font-bold px-2 rounded-lg"
                >
                  {{ gaveta.status ? "Conectado" : "No disponible" }}
                </span>
              </td>
              <td class="px-6 py-4">
                <span
                  class=" {{
                    gaveta.saturation
                      ? 'text-red-700 bg-red-100 '
                      : 'text-emerald-700 bg-emerald-100 '
                  }} font-bold px-2 rounded-lg"
                >
                  {{ gaveta.saturation ? "Ocupada" : "Disponible" }}
                </span>
              </td>

              <td class="w-[70%] py-4 text-center flex justify-around">
                <button
                  (click)="openGaveta(gaveta)"
                  class="p-2 bg-dagblue text-white rounded-lg w-24"
                >
                  Abrir
                </button>
                <button class="p-2 bg-dagblue text-white rounded-lg w-24"
                [routerLink]="['/gabetas-details', gaveta._id]">
                  Detalles
                </button>
                <button
                  class="p-2 bg-dagblue text-white rounded-lg w-24"
                  [routerLink]="['/gabetas-log', gaveta.id_gabeta]"
                >
                  Historial
                </button>
                <button
                  class="p-2 bg-dagblue text-white rounded-lg w-24"
                  (click)="updateGavetaStatus(gaveta, !gaveta.status)"
                >
                  {{ gaveta.status ? "Desactivar" : "Activar" }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</div>

<div
  *ngIf="showAddGaveta"
  class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
>
  <div
    class="relative top-20 mx-auto p-5 border w-fit shadow-lg rounded-md bg-white"
  >
    <h3 class="text-lg font-bold mb-4">Agregar Nuevo gaveta</h3>
    <form class="gap-2 w-full">
      <!-- Locker ID -->
      <div class="grid grid-cols-3 gap-5 w-auto">
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="newIdLocker"
          >
            Numero de gaveta
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="newIdGabeta"
            type="text"
            [(ngModel)]="newGabeta.id_gabeta"
            name="id_gabeta"
          />
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="newGavetaType"
          >
            Tipo de gaveta
          </label>
          <select
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="newGavetaType"
            [(ngModel)]="newGabeta.type"
            name="gaveta_type"
          >
            <option value="" disabled selected>Selecciona un tipo</option>
            <option value="Impresora">Impresora</option>
            <option value="Caja">Contenedor</option>
            <option value="Pesa">Pesa</option>
          </select>
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="newSize"
          >
            Tamaño de gaveta
          </label>
          <select
            id="newSize"
            [(ngModel)]="newGabeta.size"
            name="size"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            (change)="onSizeChange($event)"
          >
            <option value="" disabled selected>Selecciona un tamaño</option>
            <option *ngFor="let size of gavetasSize" [value]="size.size">
              {{ size.size }}
            </option>
          </select>
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="newDimensionGabeta"
          >
            Dimension de gaveta
          </label>
          <input
            class="shadow appearance-none border rounded bg-gray-200 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="newDimensionGabeta"
            type="text"
            [(ngModel)]="newGabeta.gabeta_dimension"
            name="gabeta_dimension"
            readonly
          />
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="newGavetaStatus"
          >
            Estatus
          </label>
          <select
            id="newGavetaStatus"
            [(ngModel)]="newGabeta.status"
            name="gaveta_status"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="" disabled selected>Selecciona un estatus</option>
            <option [value]="true">Activado</option>
            <option [value]="false">Desactivado</option>
          </select>
        </div>
        <!-- Add this section to your HTML where appropriate -->
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="gavetaQuantity"
          >
            Cantidad de Gavetas
          </label>
          <select
            id="gavetaQuantity"
            [(ngModel)]="newGabeta.quantity"
            name="quantity"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="" disabled selected>Selecciona una cantidad</option>
            <option
              *ngFor="
                let count of [].constructor(availableGavetasCount);
                let i = index
              "
              [value]="i + 1"
            >
              {{ i + 1 }}
            </option>
          </select>
        </div>
      </div>

      <!-- Botones de acción -->
      <div class="flex items-center justify-between">
        <button
          class="bg-dagblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
          (click)="addGaveta()"
        >
          Agregar
        </button>
        <button
          class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
          (click)="closeAddGaveta()"
        >
          Cancelar
        </button>
      </div>
    </form>
  </div>
</div>

<div
  *ngIf="showAddOwner"
  class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
>
  <div
    class="relative top-20 mx-auto p-5 border w-fit shadow-lg rounded-md bg-white"
  >
    <h3 class="text-lg font-bold mb-4">Asignar un dueño al locker</h3>
    <form class="gap-2 w-full">
      <!-- Locker ID -->
      <div class="grid gap-5 w-auto">
        <div class="mb-4">
          <input
            class="m-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            [(ngModel)]="search"
            placeholder="Buscar usuarios"
            (ngModelChange)="getUsers()"
            name="search"
          />
          <div>
            <table class="min-w-full bg-white shadow-md rounded-lg overflow-y">
              <thead class="bg-gray-200">
                <tr>
                  <th class="text-left p-4 font-bold text-gray-600">Nombre</th>
                  <th class="text-left p-4 font-bold text-gray-600">
                    Apellido
                  </th>
                  <th class="text-left p-4 font-bold text-gray-600">
                    Teléfono
                  </th>
                  <th class="text-left p-4 font-bold text-gray-600">Email</th>
                  <th class="text-left p-4 font-bold text-gray-600">Rol</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let user of users"
                  (click)="selectUser(user._id)"
                  class="border-b hover:bg-gray-100 transition-colors cursor-pointer"
                  [ngClass]="{ 'bg-blue-100': selectedUserId === user._id }"
                >
                  <td class="p-4 text-gray-700">{{ user.name }}</td>
                  <td class="p-4 text-gray-700">{{ user.surname }}</td>
                  <td class="p-4 text-gray-700">{{ user.phone }}</td>
                  <td class="p-4 text-gray-700">{{ user.email }}</td>
                  <td class="p-4 text-gray-700">{{ user.role }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="showOwnerDetails" class="w-1/2">
          Crear usuario para el acceso al locker
          <div>
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="newUserName"
            >
              Nombre de usuario
            </label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="newUserName"
              type="text"
              [(ngModel)]="newUser.username"
              name="name"
            />
          </div>

          <div class="mt-4">
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="newPassword"
            >
              Contraseña
            </label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="newPassword"
              type="text"
              [(ngModel)]="newUser.password"
              name="password"
              [readonly]="true"
            />
            <button
              class="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              (click)="generateRandomPassword()"
            >
              Generar Contraseña
            </button>
          </div>
        </div>
      </div>

      <!-- Botones de acción -->
      <div class="flex items-center justify-between">
        <button
          class="bg-dagblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
          (click)="openOwerUserForm()"
        >
          Siguente
        </button>
        <button
          class="bg-dagblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
          (click)="createLockerUser()"
          *ngIf="showOwnerDetails"
        >
          Agregar
        </button>
        <button
          (click)="closeAddOwner()"
          class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
        >
          Cancelar
        </button>
      </div>
    </form>
  </div>
</div>

<div
  *ngIf="showChangeOwnerModal"
  class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
>
  <div
    class="relative top-20 mx-auto p-5 border w-fit shadow-lg rounded-md bg-white"
  >
    <h3 class="text-lg font-bold mb-4">Asignar un dueño al locker</h3>
    <form class="gap-2 w-full">
      <!-- Locker ID -->
      <div class="grid gap-5 w-auto">
        <div class="mb-4">
          <input
            class="m-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            [(ngModel)]="search"
            placeholder="Buscar usuarios"
            (ngModelChange)="getUsers()"
            name="search"
          />
          <div>
            <table class="min-w-full bg-white shadow-md rounded-lg overflow-y">
              <thead class="bg-gray-200">
                <tr>
                  <th class="text-left p-4 font-bold text-gray-600">Nombre</th>
                  <th class="text-left p-4 font-bold text-gray-600">
                    Apellido
                  </th>
                  <th class="text-left p-4 font-bold text-gray-600">
                    Teléfono
                  </th>
                  <th class="text-left p-4 font-bold text-gray-600">Email</th>
                  <th class="text-left p-4 font-bold text-gray-600">Rol</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let user of users"
                  (click)="selectUser(user._id)"
                  class="border-b hover:bg-gray-100 transition-colors cursor-pointer"
                  [ngClass]="{ 'bg-blue-100': selectedUserId === user._id }"
                >
                  <td class="p-4 text-gray-700">{{ user.name }}</td>
                  <td class="p-4 text-gray-700">{{ user.surname }}</td>
                  <td class="p-4 text-gray-700">{{ user.phone }}</td>
                  <td class="p-4 text-gray-700">{{ user.email }}</td>
                  <td class="p-4 text-gray-700">{{ user.role }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="showOwnerDetails" class="w-1/2">
          Crear usuario para el acceso al locker
          <div>
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="newUserName"
            >
              Nombre de usuario
            </label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="newUserName"
              type="text"
              [(ngModel)]="newUser.username"
              name="name"
            />
          </div>

          <div class="mt-4">
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="newPassword"
            >
              Contraseña
            </label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="newPassword"
              type="text"
              [(ngModel)]="newUser.password"
              name="password"
              [readonly]="true"
            />
            <button
              class="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              (click)="generateRandomPassword()"
            >
              Generar Contraseña
            </button>
          </div>
        </div>
      </div>

      <!-- Botones de acción -->
      <div class="flex items-center justify-between">
        <button
          class="bg-dagblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
          (click)="openOwerUserForm()"
        >
          Siguiente
        </button>
        <button
          class="bg-dagblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
          (click)="updateUserLocker()"
          *ngIf="showOwnerDetails"
        >
          Agregar
        </button>
        <button
          (click)="closeChangeOwner()"
          class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
        >
          Cancelar
        </button>
      </div>
    </form>
  </div>
</div>

