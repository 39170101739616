<app-sidebar></app-sidebar>
<div class="ml-64 mt-14 bg-gray-100 min-h-screen">
  <div class="p-6">
    <h1 class="font-bold text-dagblue uppercase text-3xl mb-6">Solicitudes de Recarga</h1>
    
    <!-- Búsqueda -->
    <div class="bg-transparent p-4 rounded-lg shadow-md mb-6">
      <div class="flex items-center">
        <input type="text" [(ngModel)]="searchTerm" 
               class="flex-grow border-2 border-gray-400 rounded-full p-2 text-gray-500 focus:outline-none focus:ring-2 focus:ring-dagblue"
               placeholder="Buscar por usuario, folio, etc.">
        <button (click)="search()" 
                class="bg-[#D6542B] text-white px-6 py-2 rounded-full font-bold hover:bg-blue-900 transition duration-300">
          Buscar
        </button>
      </div>
    </div>
    
    <!-- Tabla de Solicitudes -->
    <div class="bg-[#F0F0F0] rounded-lg shadow-md overflow-hidden">
      <table class="w-full">
        <thead class="text-lg uppercase bg-[#F0F0F0] text-dagblue font-bold">
          <tr>
            <th class="px-6 py-3 text-left">Usuario</th>
            <th class="px-6 py-3 text-left">Folio</th>
            <th class="px-6 py-3 text-left">Fecha</th>
            <th class="px-6 py-3 text-left">Monto</th>
            <th class="px-6 py-3 text-left">Tipo</th>
            <th class="px-6 py-3 text-left">Estado</th>
            <th class="px-6 py-3 text-left">Acciones</th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 text-gray-500">
          <tr *ngFor="let request of rechargeRequests" class="hover:bg-gray-50">
            <td class="px-6 py-4">{{request.user_id.name}}</td>
            <td class="px-6 py-4">{{request.referenceNumber}}</td>
            <td class="px-6 py-4">{{request.requestDate | date:'dd/MM/yyyy'}}</td>
            <td class="px-6 py-4">${{request.amount.$numberDecimal}}</td>
            <td class="px-6 py-4">{{ getRechargeTypeLabel(request.rechargeType) }}</td>
            <td class="px-6 py-4">
              <span [ngClass]="{
                'px-2 py-1 rounded-full text-lg font-bold': true,
                'text-yellow-500': request.status === 'pendiente',
                'text-green-600': request.status === 'aprobada',
                'text-red-600': request.status === 'rechazada'
              }">
                {{request.status | titlecase}}
              </span>
            </td>
            <td class="px-6 py-4">
              <div class="flex justify-center space-x-2">
                <button (click)="viewProof(request._id)" 
                        class="bg-dagblue font-bold text-white px-4 py-2 rounded-full hover:bg-blue-900 transition duration-300">
                  Ver
                </button>
                <button *ngIf="request.status === 'pendiente'"
                      (click)="openApproveDialog(request)" 
                      class="bg-green-500 font-bold text-white px-4 py-2 rounded-full hover:bg-green-700 transition duration-300">
                  Aprobar
                </button>
                <button *ngIf="request.status === 'pendiente'"
                        (click)="openRejectDialog(request._id)" 
                        class="bg-red-500 font-bold text-white px-4 py-2 rounded-full hover:bg-red-700 transition duration-300">
                  Rechazar
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Paginación -->
    <div class="flex justify-center items-center mt-4">  
      <div class="flex items-center space-x-4">
        <button (click)="previousPage()" 
                [disabled]="currentPage === 1"
                class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white transition duration-300"
                [ngClass]="{'opacity-50 cursor-not-allowed': currentPage === 1}">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
        </button>
        <span class="text-xl font-bold text-gray-500">{{ currentPage }} </span>
        <button (click)="nextPage()" 
                [disabled]="currentPage === totalPages"
                class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white transition duration-300"
                [ngClass]="{'opacity-50 cursor-not-allowed': currentPage === totalPages}">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>
        </button>
      </div>
  </div>
</div>


<div *ngIf="showProofDialog" class="fixed inset-0 bg-black bg-opacity-50 flex  justify-center items-center z-50">
  <div class="bg-white p-4 rounded-lg max-w-3xl max-h-[79vh] ">
    <h2 class="text-2xl font-bold mb-4">Comprobante de Pago</h2>
    <img [src]="currentProofImage" alt="Comprobante de pago" class="max-w-full img">
    <button (click)="closeProofDialog()" 
            class="p-2 w-full bg-dagblue text-white rounded hover:bg-blue-700 transition duration-300">
      Cerrar
    </button>
  </div>
</div>

<!-- Diálogo para rechazar solicitud -->
<div *ngIf="showRejectDialog" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
  <div class="bg-white p-4 rounded-lg max-w-md w-full">
    <h2 class="text-2xl font-bold mb-4">Rechazar Solicitud</h2>
    <p class="mb-2"><strong>Tipo de Recarga:</strong> {{getRechargeTypeLabel(currentRejectRequest?.rechargeType)}}</p>
    <p class="mb-4"><strong>Monto:</strong> ${{currentRejectRequest?.amount.$numberDecimal}}</p>
    <textarea [(ngModel)]="rejectionReason" 
              placeholder="Ingrese el motivo del rechazo"
              class="w-full p-2 border rounded mb-4"
              rows="4"></textarea>
    <div class="flex justify-end">
      <button (click)="closeRejectDialog()" 
              class="px-4 py-2 bg-gray-300 text-gray-800 rounded mr-2 hover:bg-gray-400 transition duration-300">
        Cancelar
      </button>
      <button (click)="rejectRequest()" 
              class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-300">
        Rechazar
      </button>
    </div>
  </div>
</div>

<!-- Diálogo de confirmación para aprobar recarga -->
<div *ngIf="showApproveDialog" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
  <div class="bg-white p-6 rounded-lg max-w-md w-full">
    <h2 class="text-2xl font-bold mb-4">Confirmar Aprobación de Recarga</h2>
    <p class="mb-4">¿Está seguro que desea aprobar la siguiente recarga?</p>
    <div class="bg-gray-100 p-4 rounded-lg mb-4">
      <p><strong>Usuario:</strong> {{currentApproveRequest?.user_id?.name}}</p>
      <p><strong>Tipo de Recarga:</strong> {{getRechargeTypeLabel(currentApproveRequest?.rechargeType)}}</p>
      <p><strong>Monto a recargar:</strong> ${{currentApproveRequest?.amount.$numberDecimal}}</p>
      <p><strong>Fecha de solicitud:</strong> {{currentApproveRequest?.requestDate | date:'medium'}}</p>
    </div>
    <div class="flex justify-end space-x-3">
      <button (click)="closeApproveDialog()" 
              class="px-4 py-2 bg-gray-100 text-gray-800 rounded-lg hover:bg-gray-200 transition duration-300 focus:outline-none focus:ring-2 focus:ring-gray-500">
        Cancelar
      </button>
      <button (click)="confirmApproveRequest()" 
              class="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-300 focus:outline-none focus:ring-2 focus:ring-green-500">
        Confirmar Aprobación
      </button>
    </div>
  </div>
</div>

<!-- Toast Notification -->
<div *ngIf="showToast" 
     [ngClass]="{
       'fixed bottom-5 right-5 p-4 rounded-lg shadow-lg z-50': true,
       'bg-green-500 text-white': toastType === 'success',
       'bg-red-500 text-white': toastType === 'error'
     }">
  {{ toastMessage }}
</div>